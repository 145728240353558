<template>
  <nav class="navbar navbar-expand-sm" :class="navbarClass">
    <div class="container-fluid">
      <router-link
        :to="{ name: 'Home' }"
        active-class="active"
        class="navbar-brand col-1"
      >
        <img
          :src="logo"
          alt=""
          height="34"
          class="d-inline-block align-text-top"
        >
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogg"
        aria-controls="navbarTogg"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div id="navbarTogg" class="collapse navbar-collapse">
        <slot />
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              :to="{ name: 'Playlists' }"
              active-class="active"
              class="btn btn-dark"
            >
              <i class="bi bi-gear-fill" />
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  props: {
    dark: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      navbarClass: this.dark ? "navbar-light" : "navbar-dark",
      logo: this.dark
        ? process.env.BASE_URL + "images/sologo-black.png"
        : process.env.BASE_URL + "images/sologo-white.png",
    };
  },
};
</script>
