<template>
  <div class="so-color container-fluid min-vh-100">
    <Navigation />
    <div
      class="d-flex flex-column align-items-center justify-content-evenly" style="min-height: calc(100vh - 65px)"
    >
      <div class="align-self-center text-center">
        <div class="mb-5">
          <h1>Pizzaiolo Stress Level</h1>
          <input
            id="customRange1"
            type="range"
            min="0"
            max="100"
            class="form-range mt-4"
            value="0"
          >
        </div>
        <div>
          <h1>
            Vegi Level
          </h1>
          <input
            id="customRange1"
            type="range"
            min="0"
            max="100"
            class="form-range mt-4"
            value="0"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Navigation from "@/components/Navigation";
export default {
  name: "ServiceApp",
  components: {
    Navigation
  },
};
</script>
<style scoped>
.so-color {
  background-color: var(--so-purple) !important; 
  color: white;
}
</style>
<style>
a, a:hover, a:focus, a:active { 
  color: white;
}
</style>


